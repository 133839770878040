<template>
    <div>
        <div class="drag" v-if="show">
            <div class="drag-item">{{value1}}</div>
            <div class="drag-item">+</div>
            <div class="drag-item">{{value2}}</div>
            <div class="drag-item">=</div>
            <input class="drag-item-kuang" v-model="sum" @change="inputChange">
        </div>
        <div class="drag" v-if="!show">
            <el-result icon="success" data-aos="zoom-in" :data-aos-delay="0" title="验证成功" subTitle="验证成功，请点击下方立即执行按钮" style="color: #fff;"></el-result>
        </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            value1: 0,
            value2: 0,
            sum:null,
            show:true
        };
    },
    mounted(){
        this.$emit('slidesFn',false);
        this.Randomnumber()
    },
    methods: {
        inputChange(){
            if(this.value1+this.value2 == this.sum){
                this.show = false
                this.$emit('slidesFn',true);
            }else{
                this.$message.error('验证错误，请重新输入')
                this.Randomnumber()
                this.sum = null
            }
        },
        Randomnumber(){
            let min = 1;  
            let max = 10;  
            this.value1 = Math.floor(Math.random() * (max - min + 1)) + min;
            this.value2 = Math.floor(Math.random() * (max - min + 1)) + min;
        }
    },
};
</script>

<style scoped>
::v-deep .el-result__title p {
    color: #5fc5ff;
}
::v-deep .el-result .icon-success {
    fill: #5fc5ff;
}
::v-deep .el-result__subtitle p {
    color: #5fc5ff;
}
.drag {
    width: 515px;
    height: 400px;
    margin: 0 auto;
    background: url(../assets/img/icon-6.png) no-repeat;
    background-size: 100% 90%;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap:20px;
    color:#5fc5ff;
}

.drag-item {
    font-size: 30px;
}
.drag-item:nth-child(1){
    transform: rotate(15deg);
}
.drag-item:nth-child(3){
    transform: rotate(-30deg);
}
.drag-item-kuang {
    width: 100px;
    height: 40px;
    outline: none;
    background: none;
    text-align: center;
    font-size: 30px;
    color:#5fc5ff;
    border: none;
    border: .5px solid rgba(133, 170, 233, 0.5);
    transition: all .2s;
}
.drag-item-kuang:focus{
    border-radius: 20px;
    color:#5fc5ff;
}
</style>