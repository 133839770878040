<!-- 实时数据 -->
<template>
    <div class="realData">
        <my-Titlebar>
            <template slot="TitlebarLeft">
                <div class="realData-headers">
                    <i class="el-icon-time"></i>
                    <label>{{ TimeShow }}</label>
                    <span>实时更新中...</span>
                    <i class="el-icon-loading"></i>
                </div>
            </template>
        </my-Titlebar>
        <div class="realData-contaier">
            <div class="realData-contaier-item" v-for="item,index in 10" :key="index" @click="SettingsFun" data-aos="zoom-in" :data-aos-delay="index*100">
                <div class="realData-contaier-item-name">
                    <label>保温箱01号</label>
                    <label>2023396025347691</label>
                </div>
                <div class="realData-contaier-item-main">
                    <div class="realData-contaier-item-main-top">
                        <div class="realData-contaier-item-main-top-left">
                            <div class="realData-contaier-item-main-top-leftYuan"></div>
                            <div>30℃</div>
                            <div>52.9%</div>
                        </div>
                        <div class="realData-contaier-item-main-top-right">
                            <div>温度阈值：2-8℃</div>
                            <div>湿度阈值：0-100%</div>
                            <div>连接状态：已连接</div>
                            <div>启用状态：已启用</div>
                        </div>
                    </div>
                    <div class="realData-contaier-item-main-bottom">
                        <div class="realData-contaier-item-main-bottom-left">2024-03-27 11:14:00</div>
                        <div class="realData-contaier-item-main-bottom-right">
                            <img src="../../assets/img/wifi.svg" style="width:20px;height: 20px;margin-top: -2px;">
                            <!-- <img src="../../assets/img/noWifi.svg" style="width:20px;height: 20px;"> -->
                            <cells electric="30"></cells>
                            <img src="../../assets/img/monitoring.svg" @click.stop="setStartStop" style="width:20px;height: 20px;margin-top: -2px;cursor: pointer;">
                            <!-- <img src="../../assets/img/nomonitoring.svg" style="width:20px;height: 20px;margin-top: -2px;cursor: pointer;"> -->

                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <my-pagination style="margin: 10px auto 0 auto;" :total="total" :pageSizi="initParam.pageSize" data-aos="slide-up" :data-aos-delay="0"
            :PageNum.sync="initParam.page"></my-pagination>

        <my-Modalbox :visible.sync="visible" titleBox="设备设置" iswidth="50%">
            <template slot="modalConter">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="传感器参数" name="first">
                        <div style="padding: 20px 0;max-height: 450px;overflow-y: auto;">
                            <el-form ref="form" :model="form" label-width="100px">
                                <el-form-item label="设备名称">
                                    <div style="display: flex;align-items: center;">
                                        <el-input v-model="form.name"></el-input>
                                        <popovers content="提示文字"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="传感器名称">
                                    <div style="display: flex;align-items: center;">
                                        <el-input v-model="form.name"></el-input>
                                        <popovers content="提示文字"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="传感器采样率">
                                    <div style="display: flex;align-items: center;">
                                        <el-input v-model="form.name"></el-input>
                                        <popovers content="提示文字"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="预警">
                                    <div style="display: flex;align-items: center;">
                                        <el-switch v-model="form.delivery"></el-switch>
                                        <popovers placement="right" content="推送预警消息，设为 空库后 不推送预警消息"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="温度范围">
                                    <div style="display: flex;align-items: center;">
                                        <div style="display: flex;align-items: center;">
                                            <el-input v-model="form.name"></el-input>
                                            <span style="padding: 0 20px;">-</span>
                                            <el-input v-model="form.name"></el-input>
                                        </div>
                                        <popovers content="提示文字" placement="right"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="适度范围">
                                    <div style="display: flex;align-items: center;">
                                        <div style="display: flex;align-items: center;">
                                            <el-input v-model="form.name"></el-input>
                                            <span style="padding: 0 20px;">-</span>
                                            <el-input v-model="form.name"></el-input>
                                        </div>
                                        <popovers content="提示文字" placement="right"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="预警记录数据">
                                    <div style="display: flex;align-items: center;">
                                        <el-switch v-model="form.delivery"></el-switch>
                                        <popovers placement="right" content="关闭后 只推送报警记录，不记录数据"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="启用">
                                    <div style="display: flex;align-items: center;">
                                        <el-switch v-model="form.delivery"></el-switch>
                                        <popovers placement="right" content="功能（实时、记录、报警、预警）"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="空库">
                                    <div style="display: flex;align-items: center;">
                                        <el-switch v-model="form.delivery"></el-switch>
                                        <popovers placement="right" content="启用（正常 实时、记录, 不 报警、预警）关闭（正常 实时、记录、报警、预警）"></popovers>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div style="margin:20px 0 0 100px;">
                            <el-button type="primary">立即创建</el-button>
                            <el-button>取消</el-button>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="传感器配置" name="second">
                        <div style="padding: 20px 0;">
                            <el-form ref="form" :model="form" label-width="150px">
                                <el-form-item label="类型选择">
                                    <div>
                                        <el-radio-group v-model="form.resource">
                                            <el-radio label="默认"></el-radio>
                                            <el-radio label="物流"></el-radio>
                                            <el-radio label="药店"></el-radio>

                                        </el-radio-group>
                                    </div>
                                </el-form-item>
                                <el-form-item label="实时数据显示排序">
                                    <div style="display: flex;align-items: center;">
                                        <el-input v-model="form.name"></el-input>
                                        <popovers content="越小越靠前，可以为负数"></popovers>
                                    </div>
                                </el-form-item>
                                <el-form-item label="屏蔽数据展示">
                                    <div>
                                        <el-radio-group v-model="form.resource">
                                            <el-radio label="屏蔽数据展示"></el-radio>
                                            <el-radio label="正常数据显示"></el-radio>
                                        </el-radio-group>
                                    </div>
                                </el-form-item>
                                <el-form-item label="数据视图3D视图订阅">
                                    <div style="display: flex;align-items: center;">
                                        <el-input v-model="form.name"></el-input>
                                    </div>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary">立即创建</el-button>
                                    <el-button>取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </template>
        </my-Modalbox>
        <my-Modalbox :visible.sync="stops" titleBox="远程启停验证" iswidth="50%">
            <div slot="modalConter" class="model-main">
                <Slides @slidesFn="slidesFn"></Slides>
                <div class="model-btn" @click="submit">
                    <img src="../../assets/img/btn.png">
                    <label>立即执行</label>
                </div>
            </div>
        </my-Modalbox>
    </div>
</template>

<script>
import {formatDate} from '@/utils/Times.js'
import cells from "../../components/cells.vue";
import popovers from "../../components/popovers.vue";
import Slides from '../../components/Slides.vue'
export default {
    name: "",
    components:{cells,popovers,Slides},
    data() {
        return {
            showbtn:false,
            stops:false,
            visible:false,
            activeName: 'first',
            times:'',
            TimeShow:'2024-01-01 00:00:00',
            initParam:{
                page:1,
                pageSize:10 
            },
            total:30,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            }
        }
    },
    created() {

    },
    mounted() {
        this.CurrentTime()
    },
    methods:{
        submit(){
            if(!this.showbtn){
                this.$message.error('请输入验证信息')
                return
            }else{
                this.$message.error('提交成功')
                this.stops = false
            }
        },
        slidesFn(e){//验证码
            this.showbtn = e
        },
        setStartStop(){
            this.stops = true
        },
        SettingsFun(){
            this.visible = true
        },
        CurrentTime(){
            this.times = setInterval(()=>{
                this.TimeShow = formatDate(new Date())
            },1000)
        },
    },
    beforeDestroy(){
        clearInterval(this.times)
    }
}
</script>
<style scoped>
::v-deep .el-tabs__item {
    color: #5fc5ff;
}
::v-deep .el-tabs__item.is-active {
    color: #5fc5ff !important;
}
::v-deep .el-tabs__nav-wrap::after {
    background-color: #183d7d;
}
::v-deep .el-form-item__label {
    color: #5fc5ff;
}
::v-deep .el-input__inner {
    background-color: #183d7d;
    border-radius: 4px;
    border: 1px solid #183d7d;
    color: #fff;
}
::v-deep .el-switch.is-checked .el-switch__core {
    border-color: #183d7d;
    background-color: #183d7d;
}
::v-deep .el-switch__core {
    border: 1px solid #f56c6c;
    background: #f56c6c;
}
::v-deep .el-radio {
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    outline: 0;
    margin-right: 30px;
}
</style>
<style lang="scss">
@import url('../../assets/scss/realData.scss');
</style>