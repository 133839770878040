<template>
    <div class="">
        <el-popover :placement="placement" title="提示" width="200" trigger="hover" style="margin-left: 10px;cursor: pointer;"
            :content="content">
            <i class="el-icon-info" slot="reference"></i>
        </el-popover>
    </div>
</template>

<script>
export default {
    props:{
        content:{
            type:String,
            default:''
        },
        placement:{
            type:String,
            default:'left'
        }
    },
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    }
}
</script>
<style lang="scss">
/* @import url(); 引入css类 */
</style>