<!-- 电池 -->
<template>
    <div class="cells">
        <div class="cells-dc">
            <div class="cells-left">
                {{electric}}
            </div>
            <div class="cells-right"></div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        electric:{
            type:Number,
            default:'0'
        }
    }
}
</script>
<style lang="scss">
.cells-dc{
    display: flex;
    align-items: center;
}
.cells-left{
    width: 30px;
    border: 1px solid #5fc5ff;
    font-size: 10px;
    border-radius: 2px;
    color:#5fc5ff;
    text-align: center;
}
.cells-right{
    height:5px;
    width: 2px;
    border-top: 1px solid #5fc5ff;
    border-right: 1px solid #5fc5ff;
    border-bottom: 1px solid #5fc5ff;
    border-radius: 1px;
}
</style>